/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ACCEPT_LANGUAGE, Language, PRODUCT_ID_TYPE } from '@shared/constants/constants.ts';

export const ASSET_DEFAULT_SORT_FIELD = 'assetCd';
export const EMPTY_RECORD_COUNT = 0;
export const WAREHOUSE_CONFIGURATION_TABLE_PAGE_SIZE = 1;
export const DEFAULT_CONSOLIDATION_STATUS = 'AVAILABLE';
export const SEARCH_MENU_MAX_HEIGHT = 300;
export const DEFAULT_DIMENSION_UNIT = 'in';
export const DEFAULT_WEIGHT_UNIT = 'lbs';
export const DEFAULT_DETAIL_VIEW_LOADING_COUNT = 10;
export const DEFAULT_DECIMAL_COUNT = 2;
export const ORDER_TYPE_PALLET_THRESHOLD_MIN = 0;
export const ORDER_TYPE_PALLET_THRESHOLD_MAX = 1;
export const UPDATE_EVENT = {
  DELETE: 'delete',
  UPDATE: 'update',
};
export const FIELD_EVENT = {
  CHANGE: 'change',
};

export const LAYOUT_ENTITY_CODE = {
  SUBZONE: 'SZ',
  ZONE: 'ZN',
  LOCATION: 'LC',
};

export const LAYOUT_ENTITY_TYPE_CODE = {
  DEFAULT: 'DEFAULT',
  CONSOLIDATED: 'CONSOLIDATED',
  VIRTUAL: 'VIRTUAL',
};

export const ASSET_TYPE_CODE = {
  CONTAINER: 'CONTAINER',
  VEHICLE: 'VEHICLE',
};

export const ALLOW_ONLY_POSITIVENUMBER = 'allowOnlyPositiveNumber';
export const ATTRIBUTE_FIELD = {
  LOCATION: {
    CHECK_DIGIT: 'CHECK_DIGIT',
    STOCKROOM: 'STOCKROOM',
  },
};

export const VALID_LOCATION_SKU = ['RESLOTTED', '000000000', '999999999'];

export const LOCATION_NAME_REGEX = /^[A-Z]{3}\d{4}[A-Z]{1}$/;

export const MAX_LENGTH_FIELD = {
  SUB_ZONE_CATEGORY_LABEL: 25,
  ZONE_MAP_SEQUENCE: 3,
  ZONE_NAME: 4,
  SUBZONE_NAME: 25,
  ZONE_DESCRIPTION: 25,
  SUBZONE_DESCRIPTION: 25,
  SUBZONE_LENGTH: 3,
  SUBZONE_WIDTH: 3,
  SUBZONE_HEIGHT: 3,
  SUBZONE_WEIGHT: 4,
  CHECK_DIGIT: 2,
  LOCATION_SKU: 16,
  LOCATION_NAME: 8,
  LOCATION_LOT: 18,
  LOCATION_MINIMUM: 8,
  LOCATION_MAXIMUM: 8,
  LOCATION_SLOT_FRONT: 6,
  LOCATION_SLOT_LAYER: 6,
  LOCATION_SLOT_DEEP: 6,
  LOCATION_CURRENT_WIDTH: 9,
  CONSOLIDATION_LOCATION_NAME: 25,
  LOCATION_LENGTH: 3,
  LOCATION_WIDTH: 3,
  LOCATION_HEIGHT: 3,
  LOCATION_WEIGHT: 4,
  CONSOLIDATION_LOCATION_WIDTH: 4,
  CONSOLIDATION_LOCATION_HEIGHT: 4,
  CONSOLIDATION_LOCATION_DEPTH: 4,
  CONSOLIDATION_LOCATION_ORDER: 25,
  VEHICLE_NAME: 4,
  VEHICLE_DESCRIPTION: 25,
  VEHICLE_BINS: 2,
  VEHICLE_MAX_VEHICLE_TRANSITION: 3,
  SUBZONE_RESERVE_PRIORITY: 3,
  SUBZONE_PICK_DROP_LOCATION: 8,
  SUBZONE_PUT_DROP_LOCATION: 8,
  SUBZONE_MIN_MAX_SKU: 9,
  SUBZONE_MIN_MAX_MIN: 4,
  SUBZONE_MIN_MAX_MAX: 4,
  ORDER_TYPE_FIELD: 12,
  ORDER_TYPE_DESCRIPTION: 36,
  ORDER_TYPE_PRIORITY: 2,
  ORDER_TYPE_PALLET_THRESHOLD: 1,
  ORDER_TYPE_SHUTTLE_PRIORITY: 2,
  USER_ROLE_ID: 25,
  USER_ROLE_DESCRIPTION: 25,
};

export const LOCATION_FIELD = {
  LOCATION_TYPE: 'LOCATION_TYPE',
  CHECK_DIGIT: 'CHECK_DIGIT',
  SKU: 'SKU',
  LOT: 'LOT',
  QUANTITY_UNITS: 'QUANTITY_UNITS',
  QUANTITY_RESERVED: 'QUANTITY_RESERVED',
  MINIMUM: 'MINIMUM',
  MAXIMUM: 'MAXIMUM',
  PACK_SIZE: 'PACK_SIZE',
  LOCATION_FLAG: 'LOCATION_FLAG',
  LOCATION_LOCK: 'LOCATION_LOCK',
  SLOT_FRONTS: 'SLOT_FRONTS',
  SLOT_LAYERS: 'SLOT_LAYERS',
  SLOT_DEEPS: 'SLOT_DEEPS',
  SLOT_WIDTH: 'SLOT_WIDTH',
  FULL_PALLET: 'FULL_PALLET',
  LENGTH: 'LENGTH',
  WIDTH: 'WIDTH',
  HEIGHT: 'HEIGHT',
  WEIGHT: 'WEIGHT',
};

export const CONSOLIDATION_LOCATION_FIELD = {
  ZONE_NAME: 'zoneName',
  LOCATION_TYPE: 'CONS_LOCATION_TYPE',
  CONS_LOCATION_STATUS: 'CONS_LOCATION_STATUS',
  HEIGHT: 'HEIGHT',
  WIDTH: 'WIDTH',
  DEPTH: 'DEPTH',
  ORDER: 'ORDER',
  DOC_PRINTER_LOC: 'DOC_PRINTER_LOC',
  LABEL_PRINTER_LOC: 'LABEL_PRINTER_LOC',
};

export const SUBZONE_FIELD = {
  LABEL_SORT: 'LABEL_SORT',
  PICK_PRIORITY: 'PICK_PRIORITY',
  SUBZONE_TYPE: 'SUBZONE_TYPE',
  MEZZANINE: 'MEZZANINE',
  CONVEYOR_FLAG: 'CONVEYOR_FLAG',
  MULT_PART_ALLOW_LOC: 'MULT_PART_ALLOW_LOC',
  PICK_DROP_LOCATION: 'PICK_DROP_LOCATION',
  PUT_DROP_LOCATION: 'PUT_DROP_LOCATION',
  SKU_PICK_CLASS: 'SKU_PICK_CLASS',
  ITEM_DESC_PROMPT: 'ITEM_DESC_PROMPT',
  PUT_PRIORITY: 'PUT_PRIORITY',
  CATEGORY_LABEL: 'CATEGORY_LABEL',
  BIDIRECTIONAL_PICK: 'BIDIRECTIONAL_PICK',
  PICK_METHOD: 'PICK_METHOD',
  PACK_METHOD: 'PACK_METHOD',
  ITEM_VERIFY: 'ITEM_VERIFY',
  CONTAINER_TYPE: 'CONTAINER_TYPE',
  LENGTH: 'LENGTH',
  WIDTH: 'WIDTH',
  HEIGHT: 'HEIGHT',
  WEIGHT: 'WEIGHT',
  RESERVE_COUNT: 'RSRV_COUNT',
  SLOT_COUNT: 'SLOT_COUNT',
};

export const ZONE_FIELD = {
  MAP_SEQUENCE: 'mapSequence',
};

export const ENTITY_ASSOCIATIONS = {
  ATTRIBUTES: 'ATTRIBUTES',
  LAYOUT_ATTRIBUTES: 'LAYOUT_ATTRIBUTES',
  LAYOUT_PARENT: 'LAYOUT_PARENT',
  ASSETS: 'ASSETS',
  CHILDREN: 'CHILDREN',
  XREFS: 'XREFS',
  TO_XREFS: 'TO_XREFS',
  FROM_XREFS: 'FROM_XREFS',
  LAYOUTS: 'LAYOUTS',
  PRODUCTS: 'PRODUCTS',
};

export const VEHICLE_FIELD = {
  VEHICLE_TYPE: 'VEHICLE_TYPE',
  FULL_PALLET: 'FULL_PALLET',
  PART_PALLET: 'PART_PALLET',
  PICK_METHOD: 'PICK_METHOD',
  PICK_ROUTE: 'PICK_ROUTE',
  PICKING_ALLOWED: 'PICKING_ALLOWED',
  PUTAWAY_ALLOWED: 'PUTAWAY_ALLOWED',
  BINS: 'BINS',
  MAX_VEHICLE_TRANSITION: 'MAX_VEHICLE_TRANS',
  SCAN_PICK_CONTAINER: 'SCAN_PICK_CONTAINER',
  SCAN_PICK_LOCATION: 'SCAN_PICK_LOCATION',
  SCAN_PICK_SKU: 'SCAN_PICK_SKU',
};

export const ORDER_TYPE_FIELD = {
  PRIORITY: 'PRIORITY',
  SHUTTLE_PRIORITY: 'SHUTTLE_PRIORITY',
  PALLET_THRESHOLD: 'PALLET_THRESHOLD',
  ALLOW_MERGE: 'ALLOW_MERGE',
  RELEASE_TO_LIGHT: 'RELEASE_TO_PTC_N_PTL',
  RELEASE_TO_SHUTTLE: 'RELEASE_TO_SHUTTLE',
};

export const LOCATION_FLAG_DYNAMIC = 'DYNAMIC';

export const SUBZONE_TYPE = {
  FORWARD_PICK: 'FWP',
  RESERVE: 'RSV',
};

export const USER_DEPARTMENTS = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Maintenance',
    value: 'MAINTENANCE',
  },
  {
    label: 'Inbound',
    value: 'INBOUND',
  },
  {
    label: 'Security',
    value: 'SECURITY',
  },
  {
    label: 'Transportation',
    value: 'TRANSPORTATION',
  },
  {
    label: 'Returns/Recalls',
    value: 'RETURNS_RECALLS',
  },
  {
    label: 'Outbound',
    value: 'OUTBOUND',
  },
  {
    label: 'Computer Room',
    value: 'COMPUTER_ROOM',
  },
  {
    label: 'Inventory Control',
    value: 'INVENTORY_CONTROL',
  },
  {
    label: 'Fleet Maintenance',
    value: 'FLEET_MAINTENANCE',
  },
];

export const SECURITY_LEVEL_OPTIONS = [
  {
    label: '05 - AutoZoner',
    value: '05',
  },
  {
    label: '10 - AutoZoner',
    value: '10',
  },
  {
    label: '15 - Computer Operator',
    value: '15',
  },
  {
    label: '25 - Advisor',
    value: '25',
  },
  {
    label: '30 - Personnel Clerk',
    value: '30',
  },
  {
    label: '35 - IC/Safety Mgr',
    value: '35',
  },
  {
    label: '40 - DC/OPS/HR Mgr',
    value: '40',
  },
  {
    label: '45 - SSC User',
    value: '45',
  },
];

export const LANGUAGE_OPTIONS = [
  {
    label: 'English',
    value: ACCEPT_LANGUAGE[Language.ENGLISH],
  },
  {
    label: 'Spanish',
    value: ACCEPT_LANGUAGE[Language.SPANISH],
  },
  {
    label: 'Portuguese',
    value: ACCEPT_LANGUAGE[Language.PORTUGUESE],
  },
];

export const COUNTRY_OPTIONS = [
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'United States',
    value: 'US',
  },
];

export const USER_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const USER_WAGE_CODE_OPTIONS = [
  {
    label: 'Fulltime Hourly',
    value: 'HOURLY',
  },
  {
    label: 'Part Time Hourly - Salary',
    value: 'SALARY',
  },
];

export const USER_SHIFT_OPTIONS = [
  {
    label: 'First',
    value: 'FIRST',
  },
  {
    label: 'Second',
    value: 'SECOND',
  },
  {
    label: 'Third',
    value: 'THIRD',
  },
  {
    label: 'Weekends',
    value: 'WEEKEND_FIRST',
  },
  {
    label: 'Weekends Second',
    value: 'WEEKEND_SECOND',
  },
  {
    label: 'Weekends Thirds',
    value: 'WEEKEND_THIRD',
  },
];

export const USER_SUB_GROUP_OPTIONS = [
  {
    label: 'RF Device',
    value: 'RF Device',
  },
  {
    label: 'General Whse Worker',
    value: 'General Whse Worker',
  },
  {
    label: 'HR User Admin',
    value: 'HR User Admin',
  },
  {
    label: 'Inventory Control',
    value: 'Inventory Control',
  },
  {
    label: 'Ops Manager',
    value: 'Ops Manager',
  },
  {
    label: 'RDM',
    value: 'RDM',
  },
  {
    label: 'Security Managment',
    value: 'Security Managment',
  },
  {
    label: 'Superuser',
    value: 'Superuser',
  },
  {
    label: 'Transportation',
    value: 'Transportation',
  },
  {
    label: 'IB Advisor',
    value: 'IB Advisor',
  },
  {
    label: 'OB Advisor',
    value: 'OB Advisor',
  },
  {
    label: 'IB Clerk',
    value: 'IB Clerk',
  },
  {
    label: 'OB Clerk',
    value: 'OB Clerk',
  },
];

export const USER_ROLE_OPTIONS = [
  {
    label: 'AutoZoner',
    value: 'AUTOZONER',
  },
  {
    label: 'DC Manager',
    value: 'DC_MANAGER',
  },
  {
    label: 'DC Operation Manager',
    value: 'DC_OPERATION_MANAGER',
  },
  {
    label: 'Fleet Maintenance',
    value: 'FLEET_MAINTENANCE',
  },
  {
    label: 'IB Advisor',
    value: 'IB_ADVISOR',
  },
  {
    label: 'IB Clerk',
    value: 'IB_CLERK',
  },
  {
    label: 'OB Advisor',
    value: 'OB_ADVISOR',
  },
  {
    label: 'OB Clerk',
    value: 'OB_CLERK',
  },
  {
    label: 'Security',
    value: 'SECURITY',
  },
  {
    label: 'Site Administrator',
    value: 'SITE_ADMINISTRATOR',
  },
  {
    label: 'Support Managers',
    value: 'SUPPORT_MANAGERS',
  },
  {
    label: 'Transportation',
    value: 'TRANSPORTATION',
  },
];

export const PERMISSION = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const USER_TYPE = {
  NEW: 'NEW',
  TEMP: 'TEMP',
  DC_TRANSFER: 'DC_TRANSFER',
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const SEARCH_TYPE = {
  EXACT: 'EXACT',
  PARTIAL: 'PARTIAL',
};

export const MAX_USER_VISITING_END_DATE_YEAR = 100;

export enum CAPABILITY_PERMISSION_STATUS {
  CHECKED = 'Y',
  UNCHECKED = 'N',
  PARTIAL = 'P',
}

export const LOCATION_TYPE = {
  RESERVE: 'RSRV',
};

export const PRODUCT_LIST_SEARCH_OPTIONS = [
  {
    label: 'SKU',
    value: PRODUCT_ID_TYPE.PRODUCT_ID,
  },
  {
    label: 'Part #',
    value: PRODUCT_ID_TYPE.PART_NUMBER,
  },
  {
    label: 'UPC',
    value: PRODUCT_ID_TYPE.UPC,
  },
];

export const DEFAULT_PRODUCT_LIST_SORT = 'productId';
