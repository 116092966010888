/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsClick } from '@shared/components/MasterTitle/MasterTitle.types.ts';

export const useBreadcrumb = (pathname = '') => {
  /* Constant */
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const matchingPathname = pathname || location.pathname;
  let breadcrumbs: BreadcrumbsClick = {
    data: [
      {
        label: t('Sidenav.ReferenceDataManager'),
        onClick: () => navigate(PAGE_URLS.RDM),
      },
    ],
  };

  switch (matchingPathname) {
    case PAGE_URLS.ZONE_LIST:
    case PAGE_URLS.SUB_ZONE_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.ZonesAndSubZones'),
        onClick: () => navigate(''),
      });

      break;

    case PAGE_URLS.ZONE_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.ZonesAndSubZones'),
          onClick: () => navigate(PAGE_URLS.ZONE_LIST),
        },
        {
          label: t('ZoneDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.SUB_ZONE_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.ZonesAndSubZones'),
          onClick: () => navigate(PAGE_URLS.SUB_ZONE_LIST),
        },
        {
          label: t('SubzoneDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.LOCATION_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.Locations'),
        onClick: () => navigate(PAGE_URLS.LOCATION_LIST),
      });
      break;

    case PAGE_URLS.LOCATION_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.Locations'),
          onClick: () => navigate(PAGE_URLS.LOCATION_LIST),
        },
        {
          label: t('LocationDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.CONSOLIDATION_LOCATION_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.ConsolidationLocations'),
        onClick: () => navigate(PAGE_URLS.CONSOLIDATION_LOCATION_LIST),
      });
      break;

    case PAGE_URLS.CONSOLIDATION_LOCATION_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.ConsolidationLocations'),
          onClick: () => navigate(PAGE_URLS.CONSOLIDATION_LOCATION_LIST),
        },
        {
          label: t('ConsolidationLocationDetails.Name'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.SUBZONE_RESERVE_PRIORITY_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.SubzoneReservePriority'),
        onClick: () => navigate(PAGE_URLS.SUBZONE_RESERVE_PRIORITY_LIST),
      });
      break;

    case PAGE_URLS.SUBZONE_RESERVE_PRIORITY_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.SubzoneReservePriority'),
          onClick: () => navigate(PAGE_URLS.SUBZONE_RESERVE_PRIORITY_LIST),
        },
        {
          label: t('SubzoneReservePriorityDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.VEHICLE_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.Vehicles'),
        onClick: () => navigate(PAGE_URLS.VEHICLE_LIST),
      });
      break;

    case PAGE_URLS.VEHICLE_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.Vehicles'),
          onClick: () => navigate(PAGE_URLS.VEHICLE_LIST),
        },
        {
          label: t('VehicleDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.USER_CREATE:
      breadcrumbs = {
        data: [
          {
            label: t('Sidenav.UserRolesAndPermission'),
            onClick: () => navigate(PAGE_URLS.USER_LIST),
          },
        ],
      };

      breadcrumbs.data.push({
        label: t('UserDetails.Title'),
        onClick: () => navigate(''),
      });

      break;

    case PAGE_URLS.MANAGE_SUBGROUP_PERMISSIONS:
    case PAGE_URLS.USER_PERMISSION:
      breadcrumbs = {
        data: [
          {
            label: t('Sidenav.UserRolesAndPermission'),
            onClick: () => navigate(-1),
          },
        ],
      };

      breadcrumbs.data.push({
        label: t('ManageSubgroupPermissionDetails.Title'),
        onClick: () => navigate(''),
      });

      break;

    case PAGE_URLS.SUBZONE_MIN_MAX_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.SubzoneMinMax'),
        onClick: () => navigate(PAGE_URLS.SUBZONE_MIN_MAX_LIST),
      });
      break;

    case PAGE_URLS.SUBZONE_MIN_MAX_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.SubzoneMinMax'),
          onClick: () => navigate(PAGE_URLS.SUBZONE_MIN_MAX_LIST),
        },
        {
          label: t('SubzoneMinMaxDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.ORDER_TYPE_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.OrderType'),
        onClick: () => navigate(PAGE_URLS.ORDER_TYPE_LIST),
      });
      break;

    case PAGE_URLS.ORDER_TYPE_CREATE:
      breadcrumbs.data.push(
        {
          label: t('MasterTitle.OrderType'),
          onClick: () => navigate(PAGE_URLS.ORDER_TYPE_LIST),
        },
        {
          label: t('OrderTypeDetails.Title'),
          onClick: () => navigate(''),
        }
      );

      break;

    case PAGE_URLS.PRODUCT_LIST:
      breadcrumbs.data.push({
        label: t('MasterTitle.Product'),
        onClick: () => navigate(PAGE_URLS.PRODUCT_LIST),
      });
      break;
  }

  return breadcrumbs;
};
