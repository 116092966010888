/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Icon, View, Text, Dropdown, Add } from '@az/starc-ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { lazy, startTransition, useCallback, useEffect, useState } from 'react';
import { CreateDropdownContent, CreateCountType } from './CycleCountsCreateButton.constants';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import {
  CHOOSE_ASSIGNEE_CONTENT_TYPE,
  CYCLE_COUNT_STATUS_CD,
  CYCLE_COUNT_TYPE_CD,
} from '@inventory/constants/constants';
import { useMutateCreateLocationCount } from '@inventory/services/hooks/mutateCreateLocationCount';
import styles from './CycleCountsCreateButton.module.scss';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);

export const CycleCountsCreateButton = ({ isEnableCreateBtn }: { isEnableCreateBtn: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedCountType, setCreateCountType] = useState<CreateCountType | null>(null);
  const CurrentDate = new Date().toISOString();

  const { isLoading: isMutateLocation, mutateLocationCount } = useMutateCreateLocationCount();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  /* Query Create Location count Synch API */
  useEffect(() => {
    if (selectedCountType === CreateCountType.WALL_TO_WALL_COUNT) {
      mutateLocationCount(
        {
          CreateLocationCountRequest: [
            {
              countRequest: {
                countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
                statusCd: CYCLE_COUNT_STATUS_CD.NEW,
                startTs: CurrentDate,
              },
            },
          ],
        },
        {
          onSuccess: (data) => {
            if (data && !isMutateLocation) {
              queryClient.invalidateQueries(['subzoneList']);
              const successMessage = t('Success.Action.Location.Created');
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
              if (Array.isArray(data) && data[0]?.countRequest) {
                const id = data[0].countRequest.taskId;
                navigate(PAGE_URLS.CREATE_CYCLE_COUNTS(id ?? '', ROUTES.SUBZONES));
              }
            }
          },
          onError: (error) => {
            const errorMessage = t('Errors.Action.CreateLocationCount');
            error ?? handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
          },
        }
      );
    }
  }, [selectedCountType]);

  const openModal = useCallback((type: CreateCountType) => {
    startTransition(() => setCreateCountType(type));
  }, []);

  function OnClickStartCount(): void {
    throw new Error('Function not implemented.');
  }
  return (
    <>
      <View>
        <Dropdown padding={0} className={styles['create-cycle-count__create_dropdown']}>
          <Dropdown.Button
            className={styles['create-cycle-count__create_dropdown__button']}
            disabled={!isEnableCreateBtn}
          >
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} color={!isEnableCreateBtn ? 'on-disabled' : 'on-primary'} />
              <Text
                color={!isEnableCreateBtn ? 'on-disabled' : 'on-primary'}
                size="100"
                textCase="uppercase"
              >
                {t('Create')}
              </Text>
            </View>
          </Dropdown.Button>
          <Dropdown.Content>
            {CreateDropdownContent.map((link) => (
              <Dropdown.Item
                key={link.label}
                label={t(link.label)}
                onClick={() => openModal(link.type)}
              />
            ))}
          </Dropdown.Content>
        </Dropdown>
      </View>
      {selectedCountType === CreateCountType.RISK_BASED_COUNT && (
        <LazyLoadChooseAssigneesModal
          showModal
          contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.SINGLE_ASSIGNEE}
          onClose={() => setCreateCountType(null)}
          OnClickStartCount={OnClickStartCount}
        />
      )}
    </>
  );
};
