/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { ActiveCountTable } from '../ActiveCountTable/ActiveCountTable';
import { PastCountTable } from '../PastCountTable/PastCountTable';

export const RiskBased = () => {
  return (
    <View divided padding={6} gap={6}>
      <ActiveCountTable />
      <PastCountTable />
    </View>
  );
};
