/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Button } from '@az/starc-ui';
import { useState, useEffect, lazy, Suspense } from 'react';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { EMPTY_VALUE, PAGE_SIZE, SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import { DIRECTION, SortRowsParam } from '@shared/components/Table/Table.types';

import styles from './Subzones.module.scss';
import { useSubzoneLayoutSearch } from '@inventory/services/hooks/useLayoutSearch';
import { mapWallToWallSubzoneRows } from '@inventory/utils/table/subzoneLayoutTableUtils';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import {
  CHOOSE_ASSIGNEE_CONTENT_TYPE,
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  CYCLE_COUNT_TYPE_CD,
  DEFAULT,
  SUBZONE_ENTITY_CD,
  SINGLE_PAGE_SIZE,
} from '@inventory/constants/constants';
import { DEFAULT_PAGE } from '@inbound/constants/constants';
import { Stat } from '@shared/components/Stat/Stat';
import { useTranslation } from 'react-i18next';
import { WALL_TO_WALL_SUBZONES_TABLE_COLUMNS } from '@inventory/constants/tableColumns/wallToWallSubzone';
import { WallToWallSubzoneRowType } from './Subzones.types';
import { SubzoneType } from '@inventory/types/types';
import { generateSubzoneNameFromDistinctName } from '@shared/utils/commonUtils';
import { useParams } from 'react-router-dom';
import { useEndCount } from '@inventory/services/hooks/useEndCount';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);

export const Subzones = () => {
  const [, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>(DIRECTION.ASCENDING);

  const [tableData, setTableData] = useState<SortRowsParam[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [assigneeLayoutDistName, setAssigneeLayoutDistName] = useState<string>('');
  const cycleCountPhaseData = JSON.parse(localStorage.getItem('cycleCountPhaseData') ?? '');
  const statusCd = cycleCountPhaseData.statusCd;
  const [isBtnsHidden, setIsBtnsHidden] = useState<boolean>(false);
  const [isAnyUserAssigned, setIsAnyUserAssigned] = useState<boolean>(false);

  /* Query for Layout Search data */
  const { subzoneLayoutSearchData } = useSubzoneLayoutSearch(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.LAYOUT_NAME,
            direction: direction,
          },
        ],
      },
      searchCriteria: {
        entityCd: SUBZONE_ENTITY_CD,
        entityTypeCd: DEFAULT,
      },
    },
    statusCd === CYCLE_COUNT_STATUS_CD.NEW
  );

  /* Query for Location-count Search data */
  const { wallToWallData: SubzonesCycleCountData } = useCycleCount(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: SINGLE_PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.END_TS,
            direction: SORT_ORDER.DESC,
          },
        ],
      },
      searchCriteria: {
        countRequest: {
          countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
          statusCd: [CYCLE_COUNT_STATUS_CD.NEW],
          taskId: id,
        },
        entitiyAssociations: [
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        ],
      },
    },
    statusCd === CYCLE_COUNT_STATUS_CD.INPROGRESS || statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE
  );

  /* Query for perform-action-sync data */
  const { mutateEndCount } = useEndCount();

  const onSubmit = (status: string) => {
    const currentTimestamp = new Date().toISOString();

    mutateEndCount({
      countRequest: {
        taskId: String(id),
        countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
        statusCd: status,
        endTs: currentTimestamp,
      },
    });
    if (status === CYCLE_COUNT_STATUS_CD.INPROGRESS) {
      setIsBtnsHidden(true);
    }
  };

  function handleMultipleAssignee(layoutDistinctName?: string): void {
    setAssigneeLayoutDistName(layoutDistinctName || '-');
    setShowAssigneeModal(true);
  }

  useEffect(() => {
    let hasAssignedUsers = false;

    // Fetch data based on the statusCd
    if (statusCd === CYCLE_COUNT_STATUS_CD.NEW) {
      const mappedData: WallToWallSubzoneRowType[] = [];
      subzoneLayoutSearchData?.content.map((item) => {
        mappedData.push({
          subzone: item.layout.layoutName || '',
          timeStarted: EMPTY_VALUE,
          counter: [],
          reCounter: EMPTY_VALUE,
          netAdjusted: EMPTY_VALUE,
          absolutePCAccuracy: EMPTY_VALUE,
          pcAdjustments: EMPTY_VALUE,
          variances: EMPTY_VALUE,
          locationProgress: null,
          totalActions: EMPTY_VALUE,
          locationsAssigned: EMPTY_VALUE,
          locationsCompleted: EMPTY_VALUE,
          layoutDistinctName: item.layout.layoutDistinctName || '',
        });
      });
      setTableData(mapWallToWallSubzoneRows(mappedData, handleMultipleAssignee));
    } else {
      const locationCountData: WallToWallSubzoneRowType[] = [];
      SubzonesCycleCountData?.content?.map((item) => {
        if (item && item.subzones) {
          item.subzones.map((subzone) => {
            const assignedUsers = subzone.assignedUsers || [];
            if (assignedUsers.length > 0) {
              hasAssignedUsers = true;
            }
            locationCountData.push({
              subzone: generateSubzoneNameFromDistinctName(subzone?.parentLayoutName || ''),
              timeStarted: subzone.startTs || '',
              counter:
                subzone.assignedUsers?.map(
                  (assignee) => `${assignee.firstName} ${assignee.lastName}`
                ) || [],
              reCounter: EMPTY_VALUE,
              netAdjusted:
                subzone.totalInvAmtExpected && subzone.totalInvAmtCounted
                  ? subzone.totalInvAmtCounted - subzone.totalInvAmtExpected
                  : EMPTY_VALUE,
              absolutePCAccuracy:
                subzone.totalInvAmtCounted && subzone.totalInvAmtExpected
                  ? (subzone.totalInvAmtCounted * 100) / subzone.totalInvAmtExpected
                  : EMPTY_VALUE,
              pcAdjustments: EMPTY_VALUE,
              variances: subzone.totalInvAmtExceedsVariance || EMPTY_VALUE,
              locationProgress: getLocationProgressValues(subzone),
              totalActions:
                subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected
                  ? subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected
                  : EMPTY_VALUE,
              locationsAssigned: subzone.layoutLocationsAssigend || EMPTY_VALUE,
              locationsCompleted:
                subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted
                  ? subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted
                  : EMPTY_VALUE,
            });
          });
        }
      });
      setTableData(mapWallToWallSubzoneRows(locationCountData, handleMultipleAssignee));
    }
    setIsAnyUserAssigned(hasAssignedUsers);
  }, [statusCd, subzoneLayoutSearchData, SubzonesCycleCountData]);

  const getLocationProgressValues = (subzone: SubzoneType) => {
    let minValue;
    let maxValue;

    if (subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted) {
      minValue = subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted;
    }

    if (subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected) {
      maxValue = subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected;
    }
    return { min: minValue, max: maxValue };
  };

  return (
    <>
      {showAssigneeModal && (
        <Suspense>
          <LazyLoadChooseAssigneesModal
            showModal={showAssigneeModal}
            contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.MULTIPLE_ASSIGNEE}
            onClose={() => setShowAssigneeModal(false)}
            assigneeLayoutDistName={assigneeLayoutDistName}
            taskId={id}
          />
        </Suspense>
      )}
      <View className={styles['subzone-view-screen']}>
        <View
          direction="row"
          justify="end"
          align="end"
          padding={[4, 6]}
          className={styles['subzone-view-screen__statistics-section__statistics']}
        >
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.LocationsCounted')}
            primaryText="31.79%"
            secondaryTextProps={{
              secondaryText: '700 / 2,025',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.SlotsCounted')}
            primaryText="39.88%"
            secondaryTextProps={{
              secondaryText: '650 / 1,630',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.RSRVCounted')}
            primaryText="69.54%"
            secondaryTextProps={{
              secondaryText: '280 / 455',
            }}
            width="220px"
            size="medium"
          />
        </View>

        <View gap={2} className={styles['subzone-view-screen__table-view']}>
          <Table
            columns={WALL_TO_WALL_SUBZONES_TABLE_COLUMNS}
            rows={tableData}
            isPaginated={false}
            isCheckboxDisabled={false}
            isApiLoadedData={true}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            totalPages={Math.ceil(tableData?.length / PAGE_SIZE)}
            styleVariant={TableStylingVariants.DETAILS}
            onSort={(_sorting, _columnId) => {
              _sorting.forEach((sorting) => {
                if (sorting.id === _columnId) {
                  setSortBy(_columnId);
                  setDirection(sorting.direction);
                }
              });
            }}
            onPageChange={(_pageNumber) => {
              setCurrentPage(_pageNumber);
            }}
          />
        </View>
      </View>
      {!isBtnsHidden && (
        <View gap={3} direction="row" padding={[2, 6, 4, 6]} justify="end">
          <View.Item>
            <Button
              variant="secondary"
              size="large"
              onClick={() => onSubmit(CYCLE_COUNT_STATUS_CD.COMPLETE)}
            >
              {t(`CycleCount.ChooseAssigneesModal.Cancel`)}
            </Button>
          </View.Item>
          <View.Item gapBefore={6}>
            <Button
              size="large"
              onClick={() => onSubmit(CYCLE_COUNT_STATUS_CD.INPROGRESS)}
              disabled={!isAnyUserAssigned}
            >
              {t(`CycleCount.ChooseAssigneesModal.StartCount`)}
            </Button>
          </View.Item>
        </View>
      )}
    </>
  );
};
