/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { lazy, startTransition, useCallback, useState } from 'react';

import { t } from 'i18next';

import { EMPTY_VALUE, USER_STATUS_CD } from '@shared/constants/constants';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import { generateDateString } from '@shared/utils/commonUtils';

import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import { MAX_VISIBLE_AVATARS_PO_DASHBOARD } from '@inbound/constants/constants';

const LazyLoadDetailDrawer = lazy(() =>
  import('@inbound/components/ReplenishmentDetailDrawer/ReplenishmentDetailDrawer').then(
    (module) => ({
      default: module.ReplenishmentDetailDrawer,
    })
  )
);

import * as T from './ReplenishmentDashboard.types';
import { Actionable } from '@az/starc-ui';

export const ReplenishmentDashboardCard: React.FC<T.ReplenishmentCardTypes> = ({
  data,
  statusVariant,
}) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const openDrawer = useCallback(() => {
    startTransition(() => setShowDrawer(true));
  }, []);

  const assignedUsers =
    data?.replnPlanMoves &&
    data.replnPlanMoves[0]?.replnAssignedUsers?.filter(
      (user) => user.userStatusCd === USER_STATUS_CD.ASSIGNED
    );

  return (
    <>
      <Actionable fullWidth={true} onClick={() => openDrawer()}>
        <MasterCard
          title={data.productLongDesc ?? EMPTY_VALUE}
          statusBadgeProps={{
            variant: statusVariant,
            text: String(data.priority),
          }}
          label={t('ReplenishmentDashboard.Card.DateCreated', {
            date: data.srcRequestedTs
              ? generateDateString(new Date(data.srcRequestedTs), t('DateFormat.ShortTime'))
              : EMPTY_VALUE,
          })}
          detailsData={{
            data: [
              {
                label: t('ReplenishmentDashboard.Card.Pieces', {
                  pieces:
                    data.replnPlanMoves && data.replnPlanMoves.length > 0
                      ? data.replnPlanMoves[0].totalReplnQty
                      : EMPTY_VALUE,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.SlotQty', {
                  slotQty: data.slotQty ? data.slotQty : EMPTY_VALUE,
                }),
                color: data.slotQty && +data.slotQty <= 0 ? 'error' : undefined,
              },
              ...(statusVariant !== StatusVariants.NOT_STARTED
                ? [
                    {
                      label: t('ReplenishmentDashboard.Card.Outs', {
                        outs:
                          data.currentBaseCostAmt && data.slotQty
                            ? data.currentBaseCostAmt * data.slotQty
                            : EMPTY_VALUE,
                      }),
                    },
                  ]
                : []),
              {
                label: t('ReplenishmentDashboard.Card.Subzone', {
                  subzone: data.parentLayoutName || EMPTY_VALUE,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.Vehicle', {
                  vehicle:
                    (data.replnPlanMoves &&
                      data.replnPlanMoves[0]?.replnAssets?.[0]?.assetTypeCode) ||
                    EMPTY_VALUE,
                }),
              },
              {
                label: `${t('ReplenishmentDashboard.Card.SRC', {
                  src: data.replnPlanMoves?.[0]?.fromLayoutName || EMPTY_VALUE,
                })} | ${data.layoutType}: ${data.replnPlanMoves?.[0]?.toLayoutName || EMPTY_VALUE}`,
              },
            ],
          }}
          avatarGroupData={
            assignedUsers && assignedUsers.length > 0
              ? {
                  users: assignedUsers
                    ? assignedUsers.map((user) => ({
                        id: parseInt(user.userId),
                        firstName: user.userName || '',
                        lastName: user.userName || '',
                      }))
                    : [],
                  size: 'small',
                  variant: 'assignee',
                  maxDisplay: MAX_VISIBLE_AVATARS_PO_DASHBOARD,
                }
              : undefined
          }
          topSectionDirection="column"
        />
      </Actionable>

      {showDrawer && (
        <LazyLoadDetailDrawer
          showDrawer={showDrawer}
          data={data}
          statusVariant={statusVariant}
          onDrawerClose={() => setShowDrawer(!showDrawer)}
        />
      )}
    </>
  );
};
