/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { Button, Icon, SearchBar, Skeleton, Text, useDebounce, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  SUBZONE_RESERVE_PRIORITY_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import styles from '@mdm/pages/RDM/LocationManager/Location/LocationList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import {
  ENTITY_ASSOCIATIONS,
  LAYOUT_ENTITY_TYPE_CODE,
  SEARCH_MENU_MAX_HEIGHT,
} from '@mdm/constants/constants.ts';
import { Download } from '@shared/assets/icons';
import { Add } from '@az/starc-ui-icons';
import { SubzoneReservePriorityType } from '@mdm/types/schema.type.ts';
import { mapSubzoneReservePriorityTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { useQueryClient } from '@tanstack/react-query';

export const SubzoneReservePriorityList = () => {
  /* State */
  const [reservePriorityData, setReservePriorityData] = useState<SubzoneReservePriorityType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);
  const [value, setValue] = useState<string>('');
  const breadcrumbs = useBreadcrumb();

  /* Constants */
  const { isLoading: isSubzoneUpdating, mutateSubzone } = useMutateSubzone();
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { subzonesData, isFetching: isLoading } = useGetSubzones(
    {
      searchQuery: debouncedSearchQueryValue || '',
      entityAssociations: [
        ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES,
        ENTITY_ASSOCIATIONS.LAYOUT_PARENT,
        ENTITY_ASSOCIATIONS.TO_XREFS,
        ENTITY_ASSOCIATIONS.FROM_XREFS,
      ],
    },
    !!debouncedSearchQueryValue
  );

  /* Function */
  const onViewDetails = (subzoneReservePriority: SubzoneReservePriorityType) => {
    navigate(
      PAGE_URLS.SUBZONE_RESERVE_PRIORITY_DETAILS(
        subzoneReservePriority.layoutDistinctName || '',
        subzoneReservePriority.toDistinctName
      )
    );
  };

  const onDeleteSubzoneReservePriority = (subzoneReservePriority: SubzoneReservePriorityType) => {
    const foundSubzone = subzonesData?.content.find((subzone) => {
      return subzone.layout.layoutDistinctName === subzoneReservePriority.layoutDistinctName;
    });
    if (foundSubzone) {
      let xrefs = foundSubzone.xrefs || [];
      const products = foundSubzone.products || [];

      xrefs = xrefs.map((xref) => ({
        ...xref,
        isActive: xref.toDistinctName !== subzoneReservePriority.toDistinctName,
        toEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
      }));

      mutateSubzone(
        {
          layoutKey: foundSubzone.layout.layoutKey || '',
          layoutRequest: {
            ...foundSubzone.layout,
            xrefs,
            products,
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzones']);
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('Success.Action.SubzoneReservePriority.Deleted')
              );
            }
          },
        }
      );
    }
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setValue('');
  };

  /* Hooks */
  useEffect(() => {
    if (subzonesData) {
      if (subzonesData.content.length === 0) {
        setReservePriorityData([]);
        handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
      } else {
        const data: SubzoneReservePriorityType[] = [];
        subzonesData.content.forEach((subzone) => {
          if (subzone.xrefs && subzone.xrefs.length > 0) {
            subzone.xrefs.forEach((xref) => {
              data.push({
                layoutName: subzone.layout.layoutName,
                layoutDistinctName: subzone.layout.layoutDistinctName,
                toDistinctName: xref.toDistinctName,
                priority: xref.priority.toString(),
              });
            });
          }
        });

        setReservePriorityData(data);
      }
    }
  }, [handleNotification, t, subzonesData]);

  const SubTitle = () => {
    return (
      <View direction="row" align="center" gap={4}>
        <View.Item>
          <Text size="100" color="600">
            {`${t('TotalSubzoneReservePriority')}: `}
            {isLoading ? (
              <Skeleton
                className={styles['subzone-reserve-priority__count-wrapper']}
                width="100px"
                height="24px"
              />
            ) : debouncedSearchQueryValue ? (
              reservePriorityData.length
            ) : (
              0
            )}
          </Text>
        </View.Item>
      </View>
    );
  };

  return (
    <View>
      <View className={styles['subzone-reserve-priority-list']}>
        <MasterTitle
          title={t('MasterTitle.SubzoneReservePriority')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
          subtitle={<SubTitle />}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={6}>
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.Subzone')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={SEARCH_MENU_MAX_HEIGHT}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>

                <View.Item>
                  <Button
                    size="large"
                    onClick={() => navigate(PAGE_URLS.SUBZONE_RESERVE_PRIORITY_CREATE)}
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('Create')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>
        <View padding={[4, 6]}>
          <Table
            columns={SUBZONE_RESERVE_PRIORITY_TABLE_COLUMNS}
            rows={mapSubzoneReservePriorityTableRows(
              debouncedSearchQueryValue ? reservePriorityData : [],
              onViewDetails,
              onDeleteSubzoneReservePriority,
              isSubzoneUpdating
            )}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isLoading}
            styleVariant={TableStylingVariants.DETAILS}
            totalPages={Math.ceil((reservePriorityData.length || DEFAULT_PAGE - 1) / PAGE_SIZE)}
            onSort={() => ({})}
          />
        </View>
      </View>
    </View>
  );
};
