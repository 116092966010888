/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  ASSET_COMMAND_HANDLER_URL,
  ASSET_QUERY_HANDLER_URL,
  FACILITY_CONFIG_QUERY_HANDLER_URL,
  FACILITY_CONFIG_COMMAND_HANDLER_URL,
  INBOUND_RECEIVING_COMMAND_HANDLER_URL,
  INBOUND_RECEIVING_QUERY_HANDLER_URL,
  LAYOUT_COMMAND_HANDLER_URL,
  LAYOUT_QUERY_HANDLER_URL,
  TASK_QUERY_HANDLER_URL,
  USER_QUERY_HANDLER_URL,
  TASK_COMMAND_HANDLER_URL,
  USER_COMMAND_HANDLER_URL,
  PRODUCT_QUERY_HANDLER_URL,
  REPLENISHMENT_QUERY_HANDLER_URL,
  LOCATION_COUNT_QUERY_SERVICE_URL,
  LOCATION_COUNT_COMMAND_HANDLER_URL,
  LAYOUT_QUERY_SERVICE_URL,
  INVENTORY_QUERY_HANDLER_URL,
  REPLENISHMENT_COMMAND_HANDLER_URL,
  VENDOR_QUERY_HANDLER_URL,
} from '@shared/constants/environmentConstants.ts';

export const ROUTES = {
  HOME: '',
  STORES: 'stores',
  ORDERS: 'orders',
  PRODUCTS: 'products',
  EXPORT: 'export',
  FAVORITES: 'favorites',
  PO_DASHBOARD: 'po-dashboard',
  PO_HISTORY: 'po-history',
  MOVES: 'moves',
  DOWNLOAD: 'download',
  REPLENISHMENT_DASHBOARD: 'replenishment-dashboard',
  CONTROL_DESK: 'control-desk',
  CONFIGS: 'configs',
  ORDER_RELEASE: 'order-release',
  INBOUND: 'inbound',
  INBOUND_ORDERS: 'inbound-orders',
  TRAILER_ARRIVALS: 'trailer-arrivals',
  TRAILER_ORDERS: 'trailer-orders',
  COMPLETED_PO: 'completed-po',
  TASK_MANAGER: 'task-manager',
  OUTBOUND: 'outbound',
  SHIPMENTS: 'shipments',
  CONTROL_TOWER: 'control-tower',
  ORDER_REQUEST_BILLING: 'order-requests-billing',
  TRANSPORTATION: 'transportation',
  DISPATCH_REPORT: 'dispatch-report',
  RETURNS_RECALLS: 'returns-recalls',
  PACKING_LISTS: 'packing-lists',
  INVENTORY_CONTROL: 'inventory-control',
  DISCREPANCY_CHECKS: 'discrepancy-checks',
  QCS: 'quality-control-services',
  QCS_REPORTS: 'qcs-reports',
  SLOT_CREATION: 'slot-creation',
  COMPONENTS: 'components',
  COMBINED_TABS: 'combined-tabs',
  MODALS: 'modals',
  NOTIFICATION: 'notification',
  DRAWER: 'drawer',
  PROGRESS_BAR: 'progress-bar',
  TABLES: 'tables',
  WAVES: 'waves',
  WILL_CALL_SEARCH: 'will-call-search',
  CREATE_WILL_CALL: 'create-will-call',
  MASTER_TITLE: 'master-title',
  DELIVERY_CARD: 'delivery-card',
  SEARCH: 'search',
  INVENTORY: 'inventory',
  VENDORS: 'vendors',
  SEARCH_BY_PRODUCT_LAYOUT: 'search-by-product-layout',
  SEARCH_DETAILS: 'search-details',
  REPLENISHMENT: 'replenishment',
  OPENING_AND_BACKUP: 'new-store-and-backup',
  CROSS_DOCK: 'cross-dock',
  READY_TO_REQUEST: 'ready-to-request',
  FLAGGED: 'flagged',
  READY_TO_BILL: 'ready-to-bill',
  SENT_TO_OUTBOUND: 'sent-to-outbound',
  EMPTY_STATE: 'empty-state',
  TAGS: 'tags',
  SCHEDULE: 'schedule',
  STATS: 'stats',
  STEPPER: 'stepper',
  BILL_PROCESSES: 'bill-order-processes',
  REPLENISHMENT_PROCESSES: 'replenishment-order-processes',
  ACTIVITY: 'activity',
  AUTH: 'auth/callback',
  USERS: 'users',
  TASKS: 'tasks',
  BULK_UPDATE: 'bulk-update',
  BULK_SET: 'bulk-set',
  PREFERENCES: 'preferences',
  HELP: 'help',
  LOGS: 'logs',
  LOGOUT: 'logout',
  SUMMARIZE: 'summarize',
  CHANGE_STATUS: 'change-status',
  RETRIEVE_USER: 'retrieve-current-user',
  CREDIT: 'credit',
  ITEMS: 'items',
  ORDER_HISTORY: 'history',
  RDM: 'reference-data-manager',
  FAQ: 'faq',
  GENERAL: 'general',
  LOCATION_MANAGER: 'location-manager',
  ZONE: 'zone',
  ZONE_LIST: 'zone-list',
  SUB_ZONE: 'sub-zone',
  SUB_ZONE_LIST: 'sub-zone-list',
  CREATE: 'create',
  Will_CAll: 'will-call',
  New_Store: 'new-store-and-backup',
  Long_Tail_Distribution: 'long-tail-distribution',
  Transfer: 'transfer',
  PUT_ON_HOLD: 'put-on-hold',
  EDIT: 'edit',
  REMOVE: 'remove',
  STORE_DETAILS: 'store-details',
  LOCATION: 'location',
  CONSOLIDATION_LOCATION: 'consolidation-location',
  DIF: 'dif',
  LAYOUT: 'layouts',
  API_VERSION: 'v1',
  ASSET: 'assets',
  VEHICLE: 'vehicle',
  SUBZONE_RESERVE_PRIORITY: 'subzone-reserve-priority',
  USER: 'user',
  USER_LIST: 'user-list',
  NEW_USER_LIST: 'new-user-list',
  DC_TRANSFER_USER_LIST: 'dc-transfer-user-list',
  INVENTORY_MANAGER: 'inventory-manager',
  PO_FINALIZATION: 'po-finalization',
  OUTBOUND_MANAGER: 'outbound-manager',
  PERMISSION: 'permission',
  SHIFT: 'shifts',
  GOALS: 'goals',
  FINALIZATION_DASHBOARD: 'finalization-dashboard',
  SUBZONE_MIN_MAX: 'sku-subzone-min-max',
  WAREHOUSE_PRODUCTS: 'warehouse-products',
  OUTBOUND_CONFIGURATION: 'outbound-configuration',
  ORDER_TYPES: 'order-types',
  RETRIEVE_DASHBOARDS: 'retrieve-dashboards',
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  BULK_CREATE: 'bulk-create',
  CYCLE_COUNTS: 'cycle-counts',
  CYCLE_COUNTS_WALL_TO_WALL: 'wall-to-wall',
  CYCLE_COUNTS_INVENTORY: 'inventory',
  CYCLE_COUNTS_RISK_BASED: 'risk-based',
  CYCLE_COUNTS_CUSTOM: 'custom',
  USER_ROLES: 'user-roles',
  ROLES: 'roles',
  USER_PERMISSIONS: 'user-permissions',
  LIST: 'list',
  CAPABILITY_GROUPS: 'capability-groups',
  ASSIGN_USERS: 'assign-users',
  REPLENISHMENTS: 'replenishments',
  LOCATION_COUNTS: 'location-counts',
  LAYOUTS: 'layouts',
  CYCLE_COUNTS_DETAILS: 'cycle-counts-details',
  CYCLE_COUNT: 'cycle-counts',
  OVERVIEW: 'overview',
  SKUS_WITH_VARIENCES: 'skus-with-variances',
  SUBZONES: 'subzones',
  UPDATE_PERMISSIONS: 'update-permissions',
  PERFORM_ACTION_SYNC: 'perform-action-sync',
  GET_DASHBOARD_MATRICS: 'get-dashboard-metrics',
  PRODUCT_MANAGER: 'product-manager',
  PRODUCT_LIST: 'product-list',
};

export const USER_ENDPOINTS = {
  RETRIEVE_USER: `/${ROUTES.USERS}/${ROUTES.RETRIEVE_USER}`,
  CREATE_USER: `${USER_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.USERS}`,
  USERS_SEARCH: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.USERS}/${ROUTES.SEARCH}`,
  SHIFTS_SEARCH: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.SHIFT}/${ROUTES.SEARCH}`,
  UPDATE_USER: (userKey: string) =>
    `${USER_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.USERS}/${userKey}`,
  ASSIGN_USER_TO_USER_ROLE: `${USER_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ROLES}/${ROUTES.ASSIGN_USERS}`,
  GET_USER_ROLES: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ROLES}/${ROUTES.SEARCH}`,
  CREATE_BULK_USER_ROLE: `${USER_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ROLES}/${ROUTES.BULK_CREATE}`,
};

export const BILL_PROCESS_ENDPOINTS = {
  BASE_URL: `/${ROUTES.BILL_PROCESSES}`,
  GET_BILL: (requestId: string) => `/${ROUTES.BILL_PROCESSES}/${requestId}`,
};

export const REPLENISHMENT_PROCESS_ENDPOINTS = {
  BASE_URL: `/${ROUTES.REPLENISHMENT_PROCESSES}`,
  GET_RO_REQUEST: (requestId: string) => `/${ROUTES.REPLENISHMENT_PROCESSES}/${requestId}`,
};

export const STORE_ENDPOINTS = {
  GET_STORE: (storeId: string) => `/${ROUTES.STORES}/${storeId}`,
  GET_ALL_STORES: (storeId: string) => `/${ROUTES.STORES}/search/${storeId}`,
  POST_STORE_ACTIVITY: (storeId: string) => `/${ROUTES.STORES}/${storeId}/${ROUTES.ACTIVITY}`,
  POST_STORE_STATUS: (storeId: string) => `/${ROUTES.STORES}/${ROUTES.CHANGE_STATUS}/${storeId}`,
} as const;

export const WAVE_ENDPOINTS = {
  GET_ALL_WAVES: `${ROUTES.WAVES}`,
  GET_WAVE: (waveId: string) => `/${ROUTES.WAVES}/${waveId}`,
} as const;

export const ORDER_ENDPOINTS = {
  GET_ORDERS: `/${ROUTES.ORDERS}`,
  GET_ORDER_HISTORY: `/${ROUTES.ORDERS}/${ROUTES.ORDER_HISTORY}`,
  GET_ORDER: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}`,
  CREDIT_ORDER: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}/${ROUTES.CREDIT}`,
  GET_PRODUCTS: `/${ROUTES.ORDERS}/${ROUTES.SEARCH}`,
  DELETE_ORDER: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}`,
  DELETE_ORDER_PRODUCTS: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}/${ROUTES.ITEMS}`,
  VERIFY_ORDER_PRODUCTS: `/${ROUTES.ORDERS}/${ROUTES.FLAGGED}`,
  POST_ORDER_ACTIVITY: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}/${ROUTES.ACTIVITY}`,
  POST_ORDER_SUMMARY: `/${ROUTES.ORDERS}/${ROUTES.SUMMARIZE}`,
  CREATE_WILL_CALL: `/${ROUTES.ORDERS}/${ROUTES.CREATE_WILL_CALL}`,
} as const;

export const PRODUCT_ENDPOINTS = {
  GET_PRODUCTS: `/${ROUTES.PRODUCTS}/${ROUTES.SEARCH}`,
} as const;

export const EXPORT_ENDPOINTS = {
  GET_ORDERS: `/${ROUTES.EXPORT}${ORDER_ENDPOINTS.GET_ORDERS}`,
  GET_PRODUCTS: `/${ROUTES.EXPORT}${ORDER_ENDPOINTS.GET_PRODUCTS}`,
} as const;

export const REPLENISHMENT_ORDER_PROCESSES_ENDPOINTS = {
  POST_CREATE_REQUEST: `/${ROUTES.REPLENISHMENT_PROCESSES}`,
};

export const BILL_ORDER_PROCESSES_ENDPOINTS = {
  POST_CREATE_REQUEST: `/${ROUTES.BILL_PROCESSES}`,
};

export const LOG_ENDPOINTS = {
  BASE_URL: `/${ROUTES.LOGS}`,
} as const;

export const LAYOUT_ENDPOINTS = {
  GET_LAYOUTS: `${LAYOUT_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.LAYOUT}/${ROUTES.SEARCH}`,
  GET_PRODUCT_BY_LAYOUT: `${LAYOUT_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.LAYOUT}/${ROUTES.SEARCH_BY_PRODUCT_LAYOUT}`,
  CREATE_LAYOUT: `${LAYOUT_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.LAYOUT}`,
  UPDATE_LAYOUT: (layoutKey: string) =>
    `${LAYOUT_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.LAYOUT}/${layoutKey}`,
} as const;

export const INVENTORY_ENDPOINTS = {
  GET_INVENTORY: `${INVENTORY_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.INVENTORY}/${ROUTES.SEARCH}`,
};

export const ASSET_ENDPOINTS = {
  GET_ASSETS: `${ASSET_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ASSET}/${ROUTES.SEARCH}`,
  CREATE_ASSET: `${ASSET_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ASSET}`,
  UPDATE_ASSET: (assetKey: string) =>
    `${ASSET_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ASSET}/${assetKey}`,
} as const;

export const ORDER_TYPE_ENDPOINTS = {
  GET_ORDER_TYPES: `${FACILITY_CONFIG_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ORDER_TYPES}/${ROUTES.SEARCH}`,
  CREATE_BULK_ORDER_TYPE: `${FACILITY_CONFIG_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ORDER_TYPES}/${ROUTES.BULK_CREATE}`,
  UPDATE_ORDER_TYPE: (orderTypeCode: string) =>
    `${FACILITY_CONFIG_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.ORDER_TYPES}/${orderTypeCode}`,
} as const;

export const INBOUND_RECEIVING_ENDPOINTS = {
  GET_INBOUND_ORDERS: `${INBOUND_RECEIVING_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.INBOUND_ORDERS}/${ROUTES.SEARCH}`,
  GET_INBOUND_ORDERS_BY_SOURCE_ORDER_AND_RECEIPT: (sourceOrderNumber: string, receiptId: string) =>
    `${INBOUND_RECEIVING_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.INBOUND_ORDERS}/${sourceOrderNumber}/${ROUTES.TRAILER_ORDERS}/${receiptId}`,
  CREATE_TRAILER_ARRIVALS: `${INBOUND_RECEIVING_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.TRAILER_ARRIVALS}`,
  UPDATE_TRAILER_ARRIVALS: (receiptId: string) =>
    `${INBOUND_RECEIVING_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.TRAILER_ARRIVALS}/${receiptId}`,
  GET_TRAILER_ORDERS: `${INBOUND_RECEIVING_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.TRAILER_ORDERS}/${ROUTES.SEARCH}`,
  GET_INBOUND_ORDERS_BY_TIME: `${INBOUND_RECEIVING_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.INBOUND_ORDERS}/${ROUTES.RETRIEVE_DASHBOARDS}`,
  GET_REPLENISHMENTS: `${REPLENISHMENT_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.REPLENISHMENTS}/${ROUTES.SEARCH}`,
  DOWNLOAD_REPLENISHMENTS: `${REPLENISHMENT_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.REPLENISHMENTS}/${ROUTES.MOVES}/${ROUTES.DOWNLOAD}`,
  CREATE_REPLENISHMENTS: `${REPLENISHMENT_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.REPLENISHMENTS}`,
  UPDATE_REPLENISHMENTS: (replenMoveId: string) =>
    `${REPLENISHMENT_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.REPLENISHMENTS}/${replenMoveId}`,
} as const;

export const VENDOR_SEARCH_ENDPOINTS = {
  GET_VENDORS: `${VENDOR_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.VENDORS}/${ROUTES.SEARCH}`,
} as const;

export const FACILITY_CONFIG_ENDPOINTS = {
  GET_FACILITY_CONFIG: `${FACILITY_CONFIG_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.CONFIGS}/${ROUTES.SEARCH}`,
} as const;

export const TASK_SEARCH_ENDPOINTS = {
  GET_TASK_SEARCH_DATA: `${TASK_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.TASKS}/${ROUTES.SEARCH}`,
  GET_GOALS_SEARCH_DATA: `${TASK_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.GOALS}/${ROUTES.SEARCH}`,
} as const;

export const USER_SEARCH_ENDPOINTS = {
  GET_USER_SEARCH_DATA: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.USERS}/${ROUTES.SEARCH}`,
} as const;

export const SHIFT_ENDPOINTS = {
  GET_SHIFTS: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.SHIFT}/${ROUTES.SEARCH}`,
} as const;

export const PRODUCT_SEARCH_ENDPOINTS = {
  GET_WAREHOUSE_PRODUCT_SEARCH_DATA: `${PRODUCT_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.WAREHOUSE_PRODUCTS}/${ROUTES.SEARCH_DETAILS}`,
} as const;

export const LOCATION_COUNT_SEARCH_ENDPOINTS = {
  GET_LOCATION_COUNT_SEARCH_DATA: `${LOCATION_COUNT_QUERY_SERVICE_URL}/${ROUTES.API_VERSION}/${ROUTES.LOCATION_COUNTS}/${ROUTES.SEARCH}`,
  GET_PHASE_PROGRESS_DATA: `${LOCATION_COUNT_QUERY_SERVICE_URL}/${ROUTES.API_VERSION}/${ROUTES.LOCATION_COUNTS}/${ROUTES.GET_DASHBOARD_MATRICS}`,
  DOWNLOAD_LOCATION_COUNT_SUBZONES: `${LOCATION_COUNT_QUERY_SERVICE_URL}/${ROUTES.API_VERSION}/${ROUTES.LOCATION_COUNTS}/${ROUTES.DOWNLOAD}`,
} as const;

export const LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS = {
  PERFORM_ACTION_SYNC: `${LOCATION_COUNT_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.LOCATION_COUNTS}/${ROUTES.PERFORM_ACTION_SYNC}`,
} as const;

export const LAYOUT_SEARCH_ENDPOINTS = {
  GET_LAYOUT_SEARCH_DATA: `${LAYOUT_QUERY_SERVICE_URL}/${ROUTES.API_VERSION}/${ROUTES.LAYOUTS}/${ROUTES.SEARCH}`,
} as const;

export const TASK_UPDATE_ENDPOINTS = {
  UPDATE_TASK: `${TASK_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.TASKS}/${ROUTES.BULK_UPDATE}`,
  SET_INBOUND_SHIFT_GOAL: `${TASK_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.GOALS}/${ROUTES.BULK_SET}`,
} as const;

export const CAPABILITY_GROUP_ENDPOINTS = {
  GET_CAPABILITY_GROUP_LIST: `${USER_QUERY_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.CAPABILITY_GROUPS}/${ROUTES.SEARCH}`,
  UPDATE_CAPABILITY_GROUP_PERMISSION: `${USER_COMMAND_HANDLER_URL}/${ROUTES.API_VERSION}/${ROUTES.CAPABILITY_GROUPS}/${ROUTES.UPDATE_PERMISSIONS}`,
} as const;

export const PAGE_URLS = {
  HOME: '/',
  STORES: `/${ROUTES.STORES}`,
  STORE: (storeId: number) => `/${ROUTES.STORES}/${storeId}`,
  ORDERS: `/${ROUTES.ORDERS}`,
  TASK_MANAGER: `/${ROUTES.INBOUND}/${ROUTES.TASK_MANAGER}`,
  ORDER_REQUEST_BILLING: `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}`,
  PO_DASHBOARD: `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}`,
  PO_DASHBOARD_SEARCH: `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${ROUTES.SEARCH}`,
  PO_DETAILS: (sourceOrderId: string, receiptId: string) =>
    `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${sourceOrderId}/${receiptId}`,
  PO_HISTORY: `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${ROUTES.PO_HISTORY}`,

  PO_FINALIZATION: (sourceOrderId: string, receiptId: string) =>
    `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${sourceOrderId}/${receiptId}/${ROUTES.PO_FINALIZATION}`,
  PO_EDIT: (sourceOrderId: string, receiptId: string) =>
    `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${sourceOrderId}/${receiptId}/${ROUTES.EDIT}`,
  PO_PUT_ON_HOLD: (sourceOrderId: string, receiptId: string) =>
    `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${sourceOrderId}/${receiptId}/${ROUTES.PUT_ON_HOLD}`,
  PO_REMOVE: (sourceOrderId: string, receiptId: string) =>
    `/${ROUTES.INBOUND}/${ROUTES.PO_DASHBOARD}/${sourceOrderId}/${receiptId}/${ROUTES.REMOVE}`,
  STORE_SEARCH: `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.SEARCH}`,
  REPLENISHMENT: `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.REPLENISHMENT}`,
  WAVE_DETAILS: (waveId: string) =>
    `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.WAVES}/${waveId}`,
  GENERAL_PAGE: (section: string, subSection: string, page: string, pageId?: string) =>
    `/${section}/${subSection}/${page}${pageId && `/${pageId}`}`,
  COMPONENTS_TABS: `/${ROUTES.COMPONENTS}/${ROUTES.COMBINED_TABS}`,
  PREFERENCES: `/${ROUTES.PREFERENCES}`,
  HELP: `/${ROUTES.HELP}`,
  LOGOUT: `/${ROUTES.LOGOUT}`,
  RDM: `/${ROUTES.RDM}`,
  USER_PERMISSION: `/${ROUTES.USER_PERMISSIONS}`,
  USER: `/${ROUTES.USER}`,
  LOCATION_MANAGER: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}`,
  FAQ: `/${ROUTES.RDM}/${ROUTES.FAQ}`,
  FAQ_GENERAL: `/${ROUTES.RDM}/${ROUTES.FAQ}/${ROUTES.GENERAL}`,
  FAQ_INBOUND: `/${ROUTES.RDM}/${ROUTES.FAQ}/${ROUTES.INBOUND}`,
  ZONE_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.ZONE_LIST}`,
  ZONE_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.ZONE}/${ROUTES.CREATE}`,
  ZONE_DETAILS: (zoneId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.ZONE}/${zoneId}`,
  SUB_ZONE_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUB_ZONE_LIST}`,
  SUB_ZONE_DETAILS: (subzoneId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUB_ZONE}/${subzoneId}`,
  OUTBOUND_CONTROL_DESK: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}`,
  ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}`,
  SUB_ZONE_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUB_ZONE}/${ROUTES.CREATE}`,
  OPENING_AND_BACKUP: `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.OPENING_AND_BACKUP}`,
  CROSS_DOCK: `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.CROSS_DOCK}`,
  STORE_DETAILS: (storeId: number) =>
    `/${ROUTES.OUTBOUND}/${ROUTES.ORDER_REQUEST_BILLING}/${ROUTES.STORES}/${storeId}`,
  STORE_ORDER_DETAILS: (storeId: string) =>
    `/${ROUTES.OUTBOUND}/${ROUTES.STORE_DETAILS}/${storeId}`,
  LOCATION_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.LOCATION}`,
  LOCATION_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.LOCATION}/${ROUTES.CREATE}`,
  LOCATION_DETAILS: (locationId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.LOCATION}/${locationId}`,
  CONSOLIDATION_LOCATION_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.CONSOLIDATION_LOCATION}`,
  CONSOLIDATION_LOCATION_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.CONSOLIDATION_LOCATION}/${ROUTES.CREATE}`,
  CONSOLIDATION_LOCATION_DETAILS: (locationId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.CONSOLIDATION_LOCATION}/${locationId}`,
  REPLENISHMEHT_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.REPLENISHMENT}`,
  WILLCALL_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.Will_CAll}`,
  NEWSTORE_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.New_Store}`,
  LTD_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.Long_Tail_Distribution}`,
  TRANSFER_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.Transfer}`,
  CROSS_DOCK_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.CROSS_DOCK}`,
  DIF_ORDER_RELEASE: `/${ROUTES.OUTBOUND}/${ROUTES.CONTROL_DESK}/${ROUTES.ORDER_RELEASE}/${ROUTES.DIF}`,
  VEHICLE_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.VEHICLE}`,
  VEHICLE_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.VEHICLE}/${ROUTES.CREATE}`,
  VEHICLE_DETAILS: (vehicleId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.VEHICLE}/${vehicleId}`,
  SUBZONE_RESERVE_PRIORITY_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_RESERVE_PRIORITY}`,
  SUBZONE_RESERVE_PRIORITY_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_RESERVE_PRIORITY}/${ROUTES.CREATE}`,
  SUBZONE_RESERVE_PRIORITY_DETAILS: (forwardSubzoneId: string, reserveSubzoneId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_RESERVE_PRIORITY}/${forwardSubzoneId}/${reserveSubzoneId}`,
  USER_LIST: `/${ROUTES.USER}/${ROUTES.USER_LIST}`,
  USER_CREATE: `/${ROUTES.USER}/${ROUTES.CREATE}`,
  USER_DETAILS: (userId: string) => `/${ROUTES.USER}/${userId}`,
  MANAGE_SUBGROUP_PERMISSIONS: `/${ROUTES.USER_PERMISSIONS}/${ROUTES.CREATE}`,
  FINALIZATION_DASHBOARD: `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.FINALIZATION_DASHBOARD}`,
  SUBZONE_DESCRIPANCY: (subzone: string) =>
    `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.FINALIZATION_DASHBOARD}/${ROUTES.SUB_ZONE}/${subzone}`,
  PO_DESCRIPANCY: (sourceOrderId: string) =>
    `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.FINALIZATION_DASHBOARD}/${sourceOrderId}`,
  SUBZONE_MIN_MAX_LIST: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_MIN_MAX}`,
  SUBZONE_MIN_MAX_CREATE: `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_MIN_MAX}/${ROUTES.CREATE}`,
  SUBZONE_MIN_MAX_DETAILS: (subzoneId: string, productId: string) =>
    `/${ROUTES.RDM}/${ROUTES.LOCATION_MANAGER}/${ROUTES.SUBZONE_MIN_MAX}/${subzoneId}/${productId}`,
  ORDER_TYPE_LIST: `/${ROUTES.RDM}/${ROUTES.OUTBOUND_CONFIGURATION}/${ROUTES.ORDER_TYPES}`,
  ORDER_TYPE_CREATE: `/${ROUTES.RDM}/${ROUTES.OUTBOUND_CONFIGURATION}/${ROUTES.ORDER_TYPES}/${ROUTES.CREATE}`,
  ORDER_TYPE_DETAILS: (orderTypeCode: string) =>
    `/${ROUTES.RDM}/${ROUTES.OUTBOUND_CONFIGURATION}/${ROUTES.ORDER_TYPES}/${orderTypeCode}`,
  CYCLE_COUNTS: `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.CYCLE_COUNTS}`,
  USER_ROLE_LIST: `/${ROUTES.USER_ROLES}/${ROUTES.LIST}`,
  USER_ROLE_DETAILS: (roleId: string) => `/${ROUTES.USER_ROLES}/${roleId}`,
  CYCLE_COUNTS_DETAILS: (Id: string) => `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.CYCLE_COUNT}/${Id}`,
  CREATE_CYCLE_COUNTS: (Id: string, selectedTab: string) =>
    `/${ROUTES.INVENTORY_CONTROL}/${ROUTES.CYCLE_COUNT}/${Id}/${selectedTab}`,
  PERMISSION_DETAILS: (roleId: string) => `/${ROUTES.USER_PERMISSIONS}/${roleId}`,
  PRODUCT_LIST: `/${ROUTES.RDM}/${ROUTES.PRODUCT_MANAGER}/${ROUTES.PRODUCT_LIST}`,
} as const;

export const ORDER_DETAILS_URLS = {
  ORDER_DETAILS: (orderId: string) => `/${ROUTES.ORDERS}/${orderId}`,
} as const;

export const PING_URLS = {
  AUTH_URL: '/as/authorization.oauth2',
  TOKEN_URL: '/as/token.oauth2',
  USER_INFO_URL: '/idp/userinfo.openid',
  REVOKE_TOKEN_URL: '/as/revoke_token.oauth2',
};

export const AUTH_ENDPOINTS = {
  TOKEN: '/as/token.oauth2',
  GET_USER_INFO: '/idp/userinfo.openid',
  REVOKE: '/as/revoke_token.oauth2',
};
