/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { View, Text, Button, Icon, Actionable, Dropdown, Divider, Checkbox } from '@az/starc-ui';
import { statusToBadgeVariant } from '@ofm/utils/utils';
import { Download } from '@shared/assets/icons';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { generateDateString } from '@shared/utils/commonUtils';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { EndCountModal } from '../EndCountModal/EndCountModal';
import { DownloadFileType } from '@inbound/types/types';
import { DEFAULT_PAGE, DESC, FILE_TYPES } from '@inbound/constants/constants';
import styles from './CycleCountDetailsHeader.module.scss';
import { useDownloadSubzones } from '@inventory/services/hooks/useCycleCounts';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_TYPE_CD,
} from '@inventory/constants/constants';
import { PAGE_SIZE, SORT_BY } from '@shared/constants/constants';

export const CycleCountDetailsHeader = ({ progressValue }: { progressValue: number }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const rootPath = PAGE_URLS.CYCLE_COUNTS_DETAILS(id ?? '');

  const [isModalOpen, setModalOpen] = useState(false);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');

  const cycleCountPhaseData = JSON.parse(localStorage.getItem('cycleCountPhaseData') ?? '');
  const statusCd = cycleCountPhaseData.statusCd;

  //Queries
  const { refetch: downloadFile } = useDownloadSubzones({
    searchPage: {
      page: DEFAULT_PAGE,
      size: PAGE_SIZE,
      sort: [{ sortBy: SORT_BY.YEAR, direction: DESC }],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
        taskId: id,
        statusCd: [statusCd],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_PERFORM_ACTION,
      ],
    },
    type: exportType === FILE_TYPES.PDF ? 'application/pdf' : 'application/csv',
  });

  const breadcrumbs = {
    data: [
      {
        label: t('CycleCount.Title'),
        onClick: () => navigate(PAGE_URLS.CYCLE_COUNTS),
      },
      {
        label: t('CycleCount.Tabs.WallToWall'),
        onClick: () => navigate(''),
      },
    ],
  };

  const date: Date | undefined = cycleCountPhaseData.startTs
    ? new Date(cycleCountPhaseData.startTs)
    : undefined;
  const formattedDate = date ? generateDateString(date, 'DD/MM/YYYY HH:mm:ss') : '';

  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);
  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    toggleExportDropDown();
  };

  useEffect(() => {
    if (exportType !== '') {
      downloadFile()
        .then(() => {
          setIsExportInProgress(false);
          setExportType('');
          return;
        })
        .catch((_) => {
          setExportType('');
          setIsExportInProgress(false);
        });
    }
  }, [downloadFile, exportType]);

  return (
    <>
      <MasterTitle
        breadcrumbProps={breadcrumbs}
        title={t('CycleCount.Tabs.WallToWallTitle', {
          year: cycleCountPhaseData.year,
          phase: cycleCountPhaseData.phase,
        })}
        titleActionProps={{
          label: 'Favorite',
          handleClick: () => {
            return;
          },
        }}
        statusBadgeProps={{
          variant: statusToBadgeVariant(statusCd ?? ''),
          text: statusCd ?? '',
        }}
        subtitle={
          <View direction="row" gap={4}>
            <View.Item>
              <Text color="600">{`${t('CycleCount.Started', { date: formattedDate })}`}</Text>
            </View.Item>
          </View>
        }
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Dropdown
              className={styles['cycle-count-details-header__download_content']}
              padding={2}
              variant="ghost"
              width={300}
            >
              <Dropdown.Button
                className={styles['cycle-count-details-header__download_content__button']}
                onClick={toggleExportDropDown}
                disabled={isExportInProgress}
              >
                {isExportInProgress ? (
                  <Button loading={true} variant="ghost" />
                ) : (
                  <View direction="row" align="center" justify="center" gap={2}>
                    <Icon svg={Download} />
                    <Text size="100" weight="bold">
                      {t('InboundDashboard.Export.Title')}
                    </Text>
                  </View>
                )}
              </Dropdown.Button>
              <Dropdown.Content>
                <View gap={2} padding={3}>
                  <View
                    direction="row"
                    align="center"
                    gap={2}
                    className={
                      styles['cycle-count-details-header__download_content__button-details']
                    }
                    as="div"
                    attributes={{
                      onClick: () => handleDownload(FILE_TYPES.CSV),
                    }}
                  >
                    <Icon svg={Download} />
                    <Text size="075" weight="bold">
                      {t('InboundDashboard.Export.CSV')}
                    </Text>
                  </View>
                  <View
                    direction="row"
                    align="center"
                    gap={2}
                    className={
                      styles['cycle-count-details-header__download_content__button-details']
                    }
                    as="div"
                    attributes={{
                      onClick: () => handleDownload(FILE_TYPES.PDF),
                    }}
                  >
                    <Icon svg={Download} />
                    <Text size="075" weight="bold">
                      {t('InboundDashboard.Export.PDF')}
                    </Text>
                  </View>
                  <View>
                    <Divider blank color="300" />
                  </View>
                  <View direction="row" gap={2} align="center">
                    <Checkbox
                      checked={downloadWithFilters}
                      label={t('InboundDashboard.Export.FilterTitle')}
                      value="Exportfilters"
                      name="Exportfilters"
                      onChange={() => toggleDownloadFilter()}
                    />
                  </View>
                </View>
              </Dropdown.Content>
            </Dropdown>
          </View.Item>
          <ActionDropdownMenu>
            <View padding={[1, 0]}>
              <View.Item>
                <View padding={[3, 4]}>
                  <Actionable fullWidth onClick={() => navigate(`${rootPath}/${ROUTES.SUBZONES}`)}>
                    <Text>{t('CycleCount.ManageAssignees')}</Text>
                  </Actionable>
                </View>
              </View.Item>
              <View.Item>
                <View padding={[3, 4]}>
                  <Actionable fullWidth onClick={() => setModalOpen(true)}>
                    <Text color="error">{t('CycleCount.EndCount')}</Text>
                  </Actionable>
                </View>
              </View.Item>
            </View>
          </ActionDropdownMenu>
        </View>
      </MasterTitle>
      {isModalOpen && (
        <EndCountModal
          progressValue={progressValue}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
