/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import pt from 'dayjs/locale/pt';

export enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
  PORTUGUESE = 'pt',
}

export const ACCEPT_LANGUAGE = {
  [Language.ENGLISH]: 'en-US',
  [Language.SPANISH]: 'es-MX',
  [Language.PORTUGUESE]: 'pt-BR',
};

export const supportedLocales = {
  [Language.ENGLISH]: en,
  [Language.SPANISH]: es,
  [Language.PORTUGUESE]: pt,
};

export const Country = {
  US: 'US',
  CA: 'CA',
  MX: 'MX',
  BR: 'BR',
};

export const Warehouses = {
  LOCAL: [Country.US, Country.CA],
  INTERNATIONAL: [Country.MX, Country.BR],
};

export const ORGANISATION_ID = 'AZ';
export const FACILITY_ID = '000000';
export const RDM = 'RDM';
export const LAYOUT = 'LAYOUT';
export const ASSET = 'ASSET';
export const DEBOUNCE_TIMER = 320;
export const EXPIRATION_INTERVAL_DURATION = 1000 * 60;
export const EXPIRATION_MINUTES_BEFORE_REFRESH = 3;
export const IDLE_INTERVAL_DURATION = 1000 * 60 * 60;
export const IDLE_MINUTES_BEFORE_LOGOUT = 120;

export const MIN_FIELD_LENGTH = 3;
export const MAX_TEXTFIELD_LENGTH = 70;
export const MAX_REASON_LENGTH = 100;
export const MAX_TEXTAREA_LENGTH = 350;
export const MAX_VISIBLE_AVATARS = 4;
export const MAX_VISIBLE_AVATARS_HOVER = 12;
export const MAX_PAGE_SIZE = 2147483647;
export const MAX_USER_SEARCH_FIELD_LENGTH = 50;
export const PAGE_SIZE = 10;
export const ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE = 5;
export const LOCATION_ASSOCIATED_SKUS_PAGE_SIZE = 5;
export const MODAL_TABLE_PAGE_SIZE = 5;
export const DEFAULT_PAGE = 1;
export const FULL_PAGE_SIZE = 100;
export const EMPTY_VALUE = '--';

export const COMMODITY_TYPE = 'COMMODITY_TYPE';
export const COMMON = 'COMMON';
export const CONFIG = 'CONFIG';
export const INBOUND = 'INBOUND';
export const PO_TYPE = 'PO_TYPE';
export const RECEIVING = 'RECEIVING';
export const INBOUND_ORDER_STATUS = 'INBOUND_ORDER_STATUS';
export const FIRST_NAME = 'firstName';
export const USER_ID = 'userId';

export const PAGE_ERRORS = {
  STORE: 'Store',
  STORES: 'Stores',
  WAVE: 'Wave',
  WAVES: 'Waves',
  SUMMARY: 'Summary',
  ORDER: 'Order',
  ORDERS: 'Orders',
};

export const NOTIFICATION_TYPES = {
  SNACK: 'snack',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
} as const;

export const ID_PADDINGS = {
  STORE: 6,
  ORDER: 15,
};

export const DEPARTMENTS = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
  PO: 'PO',
  REPLENISHMENT: 'REPLENISHMENT',
  MAINTENANCE: 'MAINTENANCE',
  SECURITY: 'SECURITY',
  TRANSPORTATION: 'TRANSPORTATION',
  RETURNS_RECALLS: 'RETURNS/RECALLS',
  COMPUTER_ROOM: 'COMPUTER-ROOM',
  INVENTORY_CONTROL: 'INVENTORY-CONTROL',
  FLEET_MAINTENANCE: 'FLEET-MAINTENANCE',
};

export enum USER_DEPT_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DEPT_VALUE {
  MAINTENANCE = 'MAINTENANCE',
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  SECURITY = 'SECURITY',
  TRANSPORTATION = 'TRANSPORTATION',
  RETURNS_RECALLS = 'RETURNS_RECALLS',
  COMPUTER_ROOM = 'COMPUTER_ROOM',
  INVENTORY_CONTROL = 'INVENTORY_CONTROL',
  FLEET_MAINTENANCE = 'FLEET_MAINTENANCE',
}

export const USER_DEPARTMENTS = [
  {
    label: 'Maintenance',
    value: DEPT_VALUE.MAINTENANCE,
  },
  {
    label: 'Inbound',
    value: DEPT_VALUE.INBOUND,
  },
  {
    label: 'Security',
    value: DEPT_VALUE.SECURITY,
  },
  {
    label: 'Transportation',
    value: DEPT_VALUE.TRANSPORTATION,
  },
  {
    label: 'Returns/Recalls',
    value: DEPT_VALUE.RETURNS_RECALLS,
  },
  {
    label: 'Outbound',
    value: DEPT_VALUE.OUTBOUND,
  },
  {
    label: 'Computer Room',
    value: DEPT_VALUE.COMPUTER_ROOM,
  },
  {
    label: 'Inventory Control',
    value: DEPT_VALUE.INVENTORY_CONTROL,
  },
  {
    label: 'Fleet Maintenance',
    value: DEPT_VALUE.FLEET_MAINTENANCE,
  },
];

export const USER_STATUS_CD = {
  ASSIGNED: 'ASSIGNED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  PAUSED: 'PAUSED',
  CANCEL: 'CANCEL',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
};

export const SORT_BY = {
  PRIORITY: 'priority',
  YEAR: 'year',
  END_TS: 'endTs',
  LAYOUT_NAME: 'layoutName',
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const PRIORITY = {
  LOW_PRIORITY: 'Low Priority',
  HIGH_PRIORITY: 'High Priority',
};

export const ENTITY_ASSOCIATIONS = {
  ATTRIBUTES: 'ATTRIBUTES',
  COMMENTS: 'COMMENTS',
  ASSIGNMENTS: 'ASSIGNMENTS',
  ACTIVITIES: 'ACTIVITIES',
  REPLEN_REQUEST: 'REPLEN_REQUEST',
  CATEGORY: 'CATEGORY',
  SAFETY: 'SAFETY',
  PRODUCT_CODES: 'PRODUCT_CODES',
  PHYSICAL_ATTRIBUTE: 'PHYSICAL_ATTRIBUTE',
  PHYSICAL_PACKING_UNITS: 'PHYSICAL_PACKING_UNITS',
  WHSE_UOM_CONVERSION: 'WHSE_UOM_CONVERSION',
  WHSE_ATTRIBS: 'WHSE_ATTRIBS',
  VENDORS: 'VENDORS',
  PRODUCTS: 'PRODUCTS',
};

export enum ACTIONS {
  CLOSE = 'close',
  SUBMIT = 'submit',
}

export enum INPUT_TYPES {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum USER_TYPES {
  NEW = 'NEW', //user is not attached to any department and roles
  TEMP = 'TEMP', //user temporaryFl is true
  DC_TRANSFER = 'DC_TRANSFER', //the facilityId is different from the requested facilityId
}

export enum TASK_ASSIGNMENT_STATUS {
  ASSIGNED = 'ASSIGNED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  PAUSED = 'PAUSED',
  REJECTED = 'REJECTED',
  NEW = 'NEW',
}

export enum TASK_STATUS {
  ASSIGNED = 'ASSIGNED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  UNASSIGNED = 'UNASSIGNED',
  INVALID = 'INVALID',
  NEW = 'NEW',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export enum TASK_ATTRIBUTES {
  SOURCE_ORDER_NBR = 'sourceOrderNbr',
  ORDER_LOCATION_ID = 'orderLocationId',
  TRAILER_LOCATION_ID = 'trailerLocationId',
  TRAILER_LOCATION_TYPE_CD = 'trailerLocationTypeCd',
  COMMODITY_TYPE_CD = 'commodityTypeCd',
  ORDER_TYPE_CD = 'orderTypeCd',
  SUB_ZONE = 'SubZone',
  TRAILER_ARRIVAL_TS = 'trailerArrivalTs',
  VENDOR_NAME = 'vendorName',
  PRIORITY = 'priority',
  TOTAL_RECEIVED_QTY = 'totalReceivedQty',
  TOTAL_ORDERED_QTY = 'totalOrderedQty',
  TOTAL_LINE_COUNT = 'totalLineCount',
  RECEIVED_LINE_COUNT = 'receivedLineCount',
  SRC = 'fromLayoutName',
  FPS = 'toLayoutName',
  PIECES = 'totalReplenQty',
  SLOT_QTY = 'slotQty',
  OUTS = 'outs',
  VEHICLE = 'assetTypeCd',
  PRODUCT_NAME = 'productLongDesc',
  REPLENISH_SUBZONE = 'subZone',
}

export enum MOVE_USER_TO_ROLE_EVENT {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const attributeKeys = [
  TASK_ATTRIBUTES.SOURCE_ORDER_NBR,
  TASK_ATTRIBUTES.ORDER_LOCATION_ID,
  TASK_ATTRIBUTES.TRAILER_LOCATION_ID,
  TASK_ATTRIBUTES.TRAILER_LOCATION_TYPE_CD,
  TASK_ATTRIBUTES.COMMODITY_TYPE_CD,
  TASK_ATTRIBUTES.ORDER_TYPE_CD,
  TASK_ATTRIBUTES.SUB_ZONE,
  TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS,
  TASK_ATTRIBUTES.VENDOR_NAME,
  TASK_ATTRIBUTES.PRIORITY,
  TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY,
  TASK_ATTRIBUTES.TOTAL_ORDERED_QTY,
  TASK_ATTRIBUTES.TOTAL_LINE_COUNT,
  TASK_ATTRIBUTES.RECEIVED_LINE_COUNT,
  TASK_ATTRIBUTES.SRC,
  TASK_ATTRIBUTES.FPS,
  TASK_ATTRIBUTES.PIECES,
  TASK_ATTRIBUTES.SLOT_QTY,
  TASK_ATTRIBUTES.OUTS,
  TASK_ATTRIBUTES.VEHICLE,
  TASK_ATTRIBUTES.PRODUCT_NAME,
  TASK_ATTRIBUTES.REPLENISH_SUBZONE,
];

export const PRODUCT_ID_TYPE = {
  PRODUCT_ID: 'PRODUCT_ID',
  PART_NUMBER: 'PART_NUMBER',
  UPC: 'UPC',
};

export const REFETCH_INTERVAL = 300000; // 5 mins

export const RESPONSE_TYPE = 'blob';
